@keyframes fadeIn {
	from { opacity: 0; }
	to { opacty: 1; }
}

@keyframes pulse {
	0%, 10% { opacity: 1; }
	50% { opacity: 0; }
	90%, 100% { opacity: 1; }
}

@keyframes flicker {
	30%, 50%, 70%, 90%, 100% { filter: invert(0); }
	0%, 31%, 71%, 91% { filter: invert(1); }
}
