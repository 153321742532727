@import "base/base";
@import "base/reset";

@import "base/animations";
/* @import './tachyons/tachyons'; */


/*
// :::Setup
// -------------------------------------------------- */

html { font-size: 62.5%; height: 100%;}
img { max-width: 100%; }

$lightGrey: rgb(239, 239, 239);
$mediumGrey: rgb(155, 155, 155);
$darkGrey: rgb(120, 120, 120);
$charcoal: rgb(45, 25, 25);

$red: rgb(199, 70, 58);
$lightRed: rgb(249, 218, 218);

$green: rgb(158, 185, 20);
$lightGreen: rgb(198, 220, 93);
$darkGreen: rgb(98, 115, 12);

$blue: rgb(90, 183, 249);
$lightBlue: rgb(132, 193, 236);


/*
// :::Type
//-------------------------------------------------- */

/* .wf-loading {
  h1, h2, h3, h4, p, li, h5, input { visibility: hidden; }
} */

$lydian: 'Monotype Lydian W01', Optima, Segoe, "Segoe UI", Candara, Calibri, Arial;
$univers: 'Univers W01', 'Helvetica Neue', helvetica, sans-serif;

body { font-family: $lydian; font-weight: 400; line-height: 1.5; }
h1 { font-size: 5rem; }
h2 { font-size: 3.8rem; }
h3 { font-size: 2rem; }
h4, p, li, input, label { font-size: 1.8rem; }
h5, label.small { font-size: 1.5rem; }

h1, h2, h3, h4 { font-weight: 500; }

p + p, p + span { margin-top: 1rem; }

strong { font-weight: 700; }
em { font-style: italic; }


/*
// :::Shared
//-------------------------------------------------- */

$column: 60rem;
$column--wide: 80rem;
$sidebar-width: 18rem;

body {
	background-color: $lightGrey;
	color: $charcoal;
}

main {
	overflow: scroll;
	padding: 0 4rem 2rem $sidebar-width;
}

.info-set {
	margin-bottom: 2rem;
}

.icon { cursor: pointer; }

/*
// :::Forms
//-------------------------------------------------- */

select, option, input, button, textarea {
	appearance: none;
	font-family: $univers;
	font-size: 1.5rem;
	padding: .5rem .25rem;
	color: $charcoal;
	border: 0;
	background-color: $whitesmoke;
	&:focus, &:hover {
		background-color: white;
		outline-width: 0;
	}
}

button { font-family: $lydian; }

select {
	padding: .5rem 2.75rem .5rem 1rem;
	border-radius: .1rem;
	background-image: url('/images/icons_select.png');
	background-repeat: no-repeat;
	background-position: calc(100% - 1rem) center;
	background-size: 1rem;
}

input, textarea {
	padding: .75rem .5rem;
}

textarea {
	height: 15rem;
	tab-size: 3;
	&.field--large { height: 50rem; }
}


.editor {
	width: 100%;
	max-width: $column;
}



.editor__title {
	margin-top: 2rem;
}

.editor__subtitle {
	font-weight: 100;
	color: $lightBlue;
}



/* .editor.thinking {

	.tabs, .forms { opacity: .5; pointer-events: none; }
	.buttonText { transition-delay: 0s; }
	.buttonText--default { opacity: 0; transform: translateY(-1.5rem); }
	.buttonText--thinking { opacity: 1; transform: none; }
	.buttonText--success { opacity: 0; transform: translateY(1.5rem); }
}

.editor.success {
	.tabs, .forms { opacity: .5; pointer-events: none;}
	.buttonText { transition-delay: 0s; }
	.buttonText--default { opacity: 0; transform: translateY(-1.5rem); }
	.buttonText--thinking { opacity: 0; transform: translateY(-1.5rem); }
	.buttonText--success { opacity: 1; transform: none; }
}
 */


.tabs {
	width: 100%;
	display: flex;
	border-bottom: 1px solid $lightGrey;
}

.tab {
	padding: .5rem 0;
	background: $lightGrey;
	color: $charcoal;
	cursor: pointer;
	border-bottom: .25rem solid transparent;
}

.tab + .tab { margin-left: 1.5rem; }

.tab:hover, .tab--active {
	margin-top: 0;
	border-bottom: .25rem solid;
}

.editor-form {
	width: 100%;
	display: none;
	position: relative;
	padding-bottom: 6rem;
	transition: .3s;

	.fieldset {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&.active { display: block; }
	&.hidden { @mixin invisible; }
}

.forms {
	margin-top: 3rem;
}


.editor-form.thinking {
	&.tab, .field { opacity: .5; pointer-events: none; }

	.buttonText--default { opacity: 0; transform: translateY(-1.5rem); }
	.buttonText--thinking { opacity: 1; transform: none; }
	.buttonText--success { opacity: 0; transform: translateY(1.5rem); }
}

.editor-form.success {
	.buttonText { transition-delay: 0s; }
	.buttonText--default { opacity: 0; transform: translateY(-1.5rem); }
	.buttonText--thinking { opacity: 0; transform: translateY(-1.5rem); }
	.buttonText--success { opacity: 1; transform: none; }
}


.form__label {
	display: block;
	color: $charcoal;
	margin-bottom: .5rem;
}

.field {
	width: 100%;
	float: left;
	transition: .3s;

	input, textarea {
		width: 100%;
	}

	&.field--half { width: calc(50% - .75rem); }

	&.hidden { display: none; }
	&.field--disabled {
		pointer-events: none;
		opacity: .5;
		input, textarea, select { color: white; }
	}
}

.field--section {
	.form__label {
		border-bottom: 1px solid;
		color: $mediumGrey;
	}
}

.field + .field--section {
	margin-top: 2rem;
}

.field__image {
	max-height: 32rem;
	height: 32rem;
	margin: .5rem auto;
	display: block;

	img {
		max-height: 100%;
		margin: 0 auto;
		display: block;
	}
}

.field__help {
	color: $darkGrey;
	font-style: italic;
}

.field--favicon {
	.input__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	input { width: calc(100% - 4.5rem) }
	.field__image {
		width: 3.5rem;
		padding: .5rem;
		background: $lightGrey;
		margin: 0 0 .5rem 0;
		height: auto;
	}
}

.field--image-strip .field__image,
.field--ogImage .field__image {
	@mixin flex-center;
	width: 40rem;
	position: relative;
	height: 40rem;
	max-height: initial;

/* 	img {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: 50;
	} */

	&:before, &:after {
		content: "";
		width: 100%;
		height: 23.75%;
		background: rgba(192, 186, 191, 0.26);
		position: absolute;
		left: 0;
		z-index: 100;
	}

	&:before {
		top: 0;
		border-bottom: 1px solid dashed blue;
	}
	&:after {
		bottom: 0;
		border-top: 1px solid dashed blue;
	}
}

.field--image-strip .field__image {
	height: 4rem;
	width: 100%;

	img {
		height: auto;
	}

	&:before {
		top: 0.5rem;
		border-bottom: 1px solid dashed blue;
	}
	&:after {
		bottom: 0.5rem;
		border-top: 1px solid dashed blue;
	}
}

.field--structure {
	width: 100%;
	position: relative;

	legend {
		position: relative;
		display: block;
		width: 100%;
		text-align: center;
		color: $darkGrey;

		&:before {
			content: '';
			position: absolute;
			top: calc(50% - 1px);
			left: 0;
			width: 100%;
			height: 2px;
			z-index: -1;
			background: $darkGrey;
			top: calc(50% - 1px);
		}
	}

	.legend__title {
		position: relative;
		display: inline-block;
		padding: 0 1rem;
		background: $lightGrey;
	}

	.title {
		text-align: center;
	}

	.field--structure {
		margin-top: 2rem;
	}
}

.repeatable__add button {
	width: 100%;
	height: 5.5rem;
	background: none;
	border: 3px solid transparent;
	transition: .3s;

	&:hover {
		background: whitesmoke;
		border: 3px solid white;
	}
}

.field__subset {
	padding: 2rem;
	width: 100%;
	border: 3px solid white;
	position: relative;

	.field__subset {
		padding: 1rem;
	}

	& + .field__subset {
		margin-top: 1rem;
		/* border-top: 2px solid black; */
	}

	.icons {
		position: absolute;
		z-index: 10;
		top: 0;
		left: -2.5rem;
		height: 100%;
		width: 1.5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.sort {
		width: 1.5rem;
		height: 1.5rem;
	}

	.icon {
		max-width: 100%;
		fill: $mediumGrey;

		&:hover { fill: black; }
	}

	.sort + .sort { margin-top: .5rem; }


}

.subset__title {
	position: relative;

	.title {
		padding: 0 2rem;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		 &:hover {
		 	text-decoration: underline;
		 }
	}

	.remove {
		position: absolute;
		right: 0;
		top: 0;
		color: $mediumGrey;
		cursor: pointer;

		&:hover {
			color: $red;
		}
	}
}

.field--structure.collapsible .field__subset  {
	.fieldset { display: none; }

	&.open  > .fieldset { display: flex; }
}

.field--changed {
}

.field--checkbox {
	display: flex;
	align-items: center;

	.checkbox__container {
		display: flex;
		align-items: center;
	}
}

.field--code {
	input, textarea {
		font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", Monaco, monospace;
		white-space: nowrap;
	}
}


/*
// :::Field States
// -------------------------------------------------- */

.field--attention { background-color: $lightRed; }

.field--invalid {

	input {
		outline: 1px solid $red;
		outline-offset: 0;
	}
}

.field--readOnly {
	input, textarea {
		background-color: $lightGrey;
		border-bottom-color: $lightGrey;
		color: $charcoal;
	}
}

button {
	margin: 1rem auto;
	width: 30rem;
	height: 4.5rem;
	max-width: 100%;
	display: block;
	cursor: pointer;
	color: black;
	border-bottom-width: 4px;
	display: inline-block;

	&:hover {
		background: $blue;
	}
}

input[type="checkbox"] {
	background-color: white;
	border: 1px solid $green;
	border-radius: .3rem;
	width: 1.6rem;
	height: 1.6rem;
	vertical-align: middle;
	display: inline-block;
	margin: .5rem;

	&:checked {
		background-color: $green;

		background-image: url("/images/icons_check-ex.png");
		background-size: cover;
		background-position: center center;
	}
}

.form__buttons {
	@mixin invisible;
	position: fixed;
	z-index: 100;
	bottom: .75rem;
	left: calc($sidebar-width);
	width: calc(100% - $sidebar-width);
	max-width: $column;
	text-align: center;

	button {
		background-color: $charcoal;
		color: white;
		position: relative;
	}

	button.submit {
		width: 100%;
	}

	button.revert {
		width: 5rem;
		float: right;

		&:hover { background-color: $red; }
	}
}

.form--invalid {
	.form__buttons {
		pointer-events: none;

		button {
			cursor: not-allowed;
			color: $red;
			opacity: 1;
		}
	}
}


.form--react.form--unsaved, .editor.thinking {
	.form__buttons { @mixin visible }
}

.buttonText {
	@mixin abs-full-size;
	@mixin flex-center;
	padding: 0 2rem;
	transition: .5s;
	/* transition-delay: .5s; */
}

.buttonText--thinking {
	opacity: 0;
	transform: translateY(1.5rem)
}

.buttonText--success {
	opacity: 0;
	transform: translateY(1.5rem)
}


button.revert {
	width: 3.5rem;
	position: relative;
	&::before, &::after {
		content: "";
		width: 50%;
		height: 1px;
		position: absolute;
		background-color: currentcolor;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
	}
	&::after { transform: translate(-50%, -50%) rotate(-45deg); }
}



.dots {
	width: 6rem;
	@mixin flex-center;
	justify-content: space-between;
}

.dot {
	width: .8rem;
	height: .8rem;
	background-color: currentColor;
	border-radius: 2rem;
	animation: fadeIn .75s infinite;
	animation-direction: alternate;
}

@for $i from 1 to 3 {
	.dot:nth-of-type($i) {
		animation-delay: resolve($i *.2s);
	}
}

.pulse { animation: pulse 4.7s infinite ease-in-out; }
.flicker { animation: flicker .4s 1 forwards; }

fieldset {
	text-align: left;
	padding: 1.5rem 0;

}

.form-field {
	width: 100%;
	margin-top: 0;
	padding: 0 0 0 1rem;

	input, textarea { width: 100%; }
}

.logo { width: 5.5rem; height: 5.5rem; }




/*
// :::Specific forms
//-------------------------------------------------- */

.editor-form.form--disableMeta {
	max-height: calc(100vh - 15rem);
	overflow: hidden;
	.field { @mixin invisible; }
	.field[data-fieldId='inherit'] { @mixin visible; position: relative; }
}

/*
// :::Messages
//-------------------------------------------------- */

.message {
	@mixin fixed-full-size;
	@mixin flex-center;
	z-index: 100;
	background: rgba(30, 30, 30, .5);
	transition: .3s;
	animation: fadeIn .4s 1 both;

	&.message--closed {
		@mixin invisible;
	}

	.modal {
		button {
			width: initial;
			height: initial;
			padding: 0 1rem;
			background: none;
			border-bottom: 2px solid rgba(255, 255, 255, .5);
			color: white;

			&:hover { border-bottom: 2px solid white; }
		}
	}
}

.message__inner {
	width: calc(100% - 2rem);
	max-width: 30rem;
	padding: 1rem 2rem;
	text-align: center;
	background-color: $lightGrey;
	border-radius: .4rem;
	box-shadow: 0px 2px 4px 1px $lightGrey;
}

.message--error {
	.message__intro { color: $red; }
	.message__inner { border-bottom: .5rem solid $red; }
}


/*
// :::Login / Registration / Forgot Password
//-------------------------------------------------- */

body.single-modal {
	padding: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal {
	position: absolute;
	top: 10%;
	left: calc(50% - 15rem);
	text-align: center;
	width: 30rem;
	margin: 10vh auto;
	padding: 1rem 2rem;
	text-align: center;

	.form-field { padding: 0; }

	.form-field input, button {
		width: 100%;
	}
}

.welcome-logo {
	position: absolute;
	top: 2rem;
	left: 50%;
	transform: translateX(-50%);
}

.modal fieldset {
	text-align: left;
	width: 100%;
	margin: 1.5rem auto;
	border-top: 2px solid $lightGrey;
	border-bottom: 2px solid $lightGrey;
	padding: 1.5rem 0;
}


/*
// :::Dashboard Home
//-------------------------------------------------- */

.categories {
	width: 100%;
	max-width: $column;
	display: flex;
	justify-content: space-between;
}

.category { width: calc(50% - .75rem); }
.category li {
	border-bottom: 1px solid transparent;
	&:hover { border-bottom-color: currentColor; }
	h4, h5 { padding: .3rem .25rem; }

	&.info {
		border-bottom-width: 0;
		color: $darkGrey;
	}

	&.secondary {
		font-size: 1.5rem;
		/* border-bottom-width: 0; */
		color: $mediumGrey;
	}
}

.category__title { font-weight: 800; border-bottom: 1px solid; }
.home {
}



/*
// :::Sidebar
//-------------------------------------------------- */



nav {

	.luckyme-logo {
		position: fixed;
		top: 0;
		left: 0;
		width: $sidebar-width;
		z-index: 100;

		img {
			max-height: 7rem;
			padding: 1rem;
			display: block;
			margin: 0 auto;
		}
	}

	.user {
		position: fixed;
		left: 0;
		bottom: 0;
		width: $sidebar-width;
		background-color: $lightGrey;
		transition: .3s;
	}

	.logout, .user__name {
		cursor: pointer;
		padding: 1rem;
		width: 100%;
		height: 100%;
		transition: .3s;
		text-align: center;
	}

	.user__name {
		pointer-events: none;
	}

	.logout {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transform: translateY(1rem);
		color: $red;
	}

	.user:hover {
		background-color: $lightGrey;

		.user__name {
			opacity: 0;
			transform: translateY(-1rem);
		}

		.logout {
			opacity: 1;
			transform: none;
		}
	}
}

.menu {
	padding-bottom: 1rem;
}

.menu__inner {
}

.submenu__title {
	margin-left: -1.5rem;
	padding: 1rem 0;
}

.submenu {
	position: relative;
	.submenu__item { display: inline; }

	& + .submenu { margin-top: 1.5rem; }
}

.submenu__label {
	/* display: none; */
	position: absolute;
	right: calc(100% + 1rem);
	color: $mediumGrey;
}

.submenu__item {
	cursor: pointer;
	border-bottom: 2px solid transparent;
	margin-bottom: .5rem;
	&.secondary { color: $mediumGrey; }
	&.active, &:hover { border-bottom: 2px solid; }
	&.create-page { color: $mediumGrey; }
}

.submenu__item.active {
	border-bottom: 2px solid;
}

.submenu__item + .submenu__item { margin-left: 1rem; }
